.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0; /* Ensure no unintended margin is added */
  padding: 0; /* Ensure no unintended padding is added */
  box-sizing: border-box;
}

.content-container {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.layout-header {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  padding: 1rem;
  width: 100%; /* Ensure the header spans the full width */
}

.layout-logo {
  margin-top: 20px;
  margin-left: 30px;
  width: 100px;
}
