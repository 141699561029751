.about-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
  }
  
  .card {
    background-color: #444a57a5;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Move card down initially */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
  }
  
  .card.visible {
    opacity: 1; /* Make visible */
    transform: translateY(0); /* Move card to original position */
  }
  
  .card h3 {
    margin-bottom: 40px;
    font-size: 1.5em;
  }
  
  .card p {
    font-size: 18px;
    color: #61dafb;
    text-align: left;
    line-height: 1.6; 
  }
  
  .contact-emails {
    list-style-type: none;
    padding-left: 0;
  }
  
  .contact-emails li {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  
  .contact-emails i {
    margin-top: 3px;
    margin-right: 10px; /* Add space between icon and email */
    color: #293336; /* Icon color */
  }
  
  .contact-emails a {
    color: #72bed1;
    text-decoration: none;
  }
  
  .contact-emails a:hover {
   color: #4892a7;
   text-decoration: underline;
  }
  