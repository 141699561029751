html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Main container */
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: calc(100% - 250px); /* Full width minus the sidebar */
  margin-left: 250px; /* Leave space for the sidebar */
  background-color: #282c34;
  box-sizing: border-box;
  transition: margin-left 0.3s ease, width 0.3s ease; 
}


/* Grid layout for upload sections */
.upload-sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Full height of the parent */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  transition: margin-left 0.3s ease; /* Smooth transition */

  overflow-y: auto;
}

/* Individual upload section */
.upload-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #1b1f25;
  width: 100%; /* Full width of the container */
  height: 100%; /* Take full height */
  box-sizing: border-box;
  margin: 0;
  position: relative;
}

.upload-container.collapsed {
  margin-left: 60px; /* Adjust margin when the sidebar is collapsed */
  width: calc(100% - 60px); /* Adjust the width to take up the additional space */
}

/* Hidden state for inactive sections */
.upload-section.hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

/* Drop zone */
.drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px;
  border: 3px solid #1b1f25;
  border-radius: 10px;
  background-color: #1b1f25;
  color: white;
  text-align: center;
}

/* Drop zone dragging state */
.drop-zone.dragging {
  background-color: #1c1f24;
}

.upload-section h2 {
  color: white;
  margin-bottom: 20px;
}

.drop-zone p {
  margin: 15px 0;
}

/* Input group styles to ensure consistent size */
.input-group,
.upload-button,
.click-upload-text {
  max-width: 300px; /* Keep the input fields and buttons constrained */
}

.click-upload-text {
  color: #61dafb;
  cursor: pointer;
  margin-bottom: 50px;
  font-size: 14px;
}

/* Center alignment for input sections */
.center-align-wrapper,
.input-pair {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px; /* Space between input groups */
  margin-top: 40px;
}

/* Spacing adjustments for the input box */
.upload-section label {
  color: white;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
  max-width: 250px;
  font-size: 14px;
  min-height: 38px;
}

.upload-section input[type="text"] {
  width: 100%;
  max-width: 250px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #61dafb;
  background-color: #1c1f24;
  color: white;
  font-size: 16px;
  text-align: center;
}

.upload-section input[type="text"]::placeholder {
  font-size: 12px;
  color: #888888;
}

/* Input group within the pair */
.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-group label {
  color: white;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
  min-height: 38px;
}

.input-group input[type="text"] {
  width: 100%;
  max-width: 250px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #61dafb;
  background-color: #1c1f24;
  color: white;
  font-size: 16px;
  text-align: center;
}

/* Upload button */
.upload-button {
  padding: 15px 20px;
  font-size: 16px;
  background-color: #61dafb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  max-width: 300px;
  z-index: 1;
}

.upload-button:hover {
  background-color: #61dafb;
}

.upload-button[disabled] {
  background-color: #888888;
  cursor: not-allowed;
  opacity: 0.6;
}

.upload-button.uploading {
  background-color: transparent;
  color: transparent;
  border: none;
}

/* Spinner animation */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61dafb;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-top: 20px; /* Ensure space below input fields */
  display: block; /* Make sure it's visible */
  margin: 20px auto 0 auto; /* Center the spinner horizontally */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Tick animation */
.tick-animation {
  font-size: 30px;
  color: #28a745;
  display: none;
  margin-top: 20px; /* Ensure space below input fields */
  display: block; /* Make sure it's visible */
  margin: 20px auto 0 auto; /* Center the spinner horizontally */
  animation: tick-animation 0.5s ease-in-out;
}

@keyframes tick-animation {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Upload done state */
.upload-button.upload-done {
  background-color: transparent;
  color: transparent;
}

.upload-button.upload-done .spinner {
  display: none;
}

.upload-button.upload-done .tick-animation {
  display: block;
}

.download-link a {
  color: #61dafb;
}

/* Responsive design */
@media (max-width: 768px) {
  .upload-sections {
    max-width: 90%;
  }

  .input-group {
    width: 100%;
  }

  .input-group input[type="text"] {
    width: 100%;
  }
}

.email-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin-top: 30px;
  border: 1px solid #61dafb;
  border-radius: 8px;
  overflow: hidden;
  background-color: #1c1f24;
}

.email-input {
  flex: 1;
  padding: 12px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 16px;
}

.send-email-button {
  padding: 12px 20px;
  border: none;
  background-color: #1c1f24;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.send-email-button:hover {
  background-color: #1b1f25;
}

.email-me-disabled {
  color: #888888;
  cursor: not-allowed;
  opacity: 0.5;
}

.email-me-active {
  color: #61dafb;
  cursor: pointer;
}

.email-sent-message {
  font-size: 14px;
  text-align: center;
  position: absolute;   
  top: -20px;           
  white-space: nowrap;   
  width: auto;          
  max-width: 100%;      
}


/* Success message (green) */
.email-sent-message.success {
  color: #28a745;
}

/* Error message (red) */
.email-sent-message.error {
  color: red; 
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}



.checkmark {
  width: 56px;
  height: 56px;
  display: block;
  stroke-width: 4;
  stroke: #eaeeea; 
  stroke-miterlimit: 10;
  margin: 20px auto;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px #4CAF50;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4CAF50;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4CAF50;
  }
}


.file-details {
  display: flex;
  align-items: center;
  background-color: #f1f1f1; /* Light background for the file details */
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content;
  transition: all 0.3s ease-in-out;
  animation: fadeSlideIn 0.5s ease forwards;
}

.file-details .file-icon {
  font-size: 24px;
  color: #007bff; /* Change the color to match your theme */
  margin-right: 10px;
}

.file-details .file-name {
  font-size: 16px;
  color: #333;
  word-wrap: break-word;
}

.file-details .file-size {
  margin-left: 15px;
  font-size: 14px;
  color: #666;
}

@keyframes fadeSlideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
  width: 100%; 
  max-width: 400px; 
  margin: 0 auto;
}

.loading-gif {
  width: 50px;
  display: block;
}

.user-name {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 18px;
  font-weight: bold;
  z-index: 1000;
  color: white;
}

.hey-text {
  font-style: italic; 
  color: #61dafb;
}