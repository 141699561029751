/* Chat Button */
.chat-button {
    position: fixed;
    bottom: 20px;
    right: 40px;
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(135deg, #00d2ff, #00d2ff) !important;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size: 1em;
    text-align: center;
    transition: none !important;
    font-style:italic;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}


.chat-button:hover {
    transform: scale(1.1); 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); 
}

/* Hide button when chat is open */
.chat-window.open + .chat-button {
    display: none !important;
}

/* Chat Window */
.chat-window {
    position: fixed;
    bottom: 20px;
    right: 30px;
    width: 500px;
    height: 550px;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    animation: fadeIn 0.3s ease-in-out;
    overflow: hidden; /* Ensures no content overflow outside the window */
}

/* Chat Header */
.chat-header {
    padding: 2px;
    background: linear-gradient(135deg, #00d2ff, #00d2ff);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

}

.chat-header h3 {
    margin-left: 10px;
    font-size: 0.9em;
    font-style: italic;
}

.close-chat {
    background: none;
    border: none;
    color: white;
    font-size: 0.7em;
    cursor: pointer;
    transition: none !important;
}

.close-chat:focus,
.close-chat:hover {
    outline: none; /* Remove the default outline */
    background-color: transparent; /* Prevent background change on hover */
}

/* Chat Body */
.chat-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    position: relative;
}

/* Chat Footer */
.chat-footer {
    padding: 10px;
    background-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.chat-footer input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

.chat-footer input:focus {
    border-color: #00d2ff; 
    box-shadow: 0 0 5px rgba(0, 210, 255, 0.5);
    outline: none; 
}
.send-button {
    padding: 10px;
    border: none;
    background: none;
    color: #7f8286;
    font-size: 1.2em;
    cursor: pointer;
    transition: none !important;
}

.send-button:focus,
.send-button:hover {
    outline: none; /* Remove the default outline */
    background-color: transparent; /* Prevent background change on hover */
}

.send-button:focus i,
.send-button:hover i {
    color: #61dafb; /* Maintain the icon color on hover/focus */
}

/* Message Bubbles */
.message {
    max-width: 75%;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    word-wrap: break-word;
    position: relative;
    color: #131619;
    animation: fadeIn 0.4s ease-out;
    font-size: 15px;
}

/* User message */
.message.user {
    background-color: #c0b4f2;
    align-self: flex-end;
    text-align: left;
    margin-left: auto; /* Align to the right */
    margin-right: 0; /* Reset margin-right */
    border-radius: 12px 12px 0 12px; /* Adjust border-radius */
    padding: 10px;
    max-width: 80%; /* Limit the width of the bubble */
    position: relative;
}

/* Bot message */
.message.bot {
    background-color: #cdcaca;
    align-self: flex-start;
    text-align: left;
    margin-right: auto; /* Align to the left */
    margin-left: 0; /* Reset margin-left */
    border-radius: 12px 12px 12px 0; /* Adjust border-radius */
    padding: 10px;
    max-width: 80%; /* Limit the width of the bubble */
    position: relative;
}


.typing-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align to the left */
    gap: 4px;
    padding: 5px 10px;
    border-radius: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    padding: 0;
}
.placeholder-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #888;
    text-align: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.typing-indicator span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #888;
    border-radius: 50%;
    animation: spinning 1.2s infinite ease-in-out;
    border: none
}

.typing-indicator span:nth-child(1) {
    animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes spinning {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
