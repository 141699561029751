/* Simple CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Default Dark Mode Styles */
:root[data-theme='dark'] {
  --background-color: #1b1f25;
  --text-color: #e0e0e0;
  --header-background-color: #282c34;
  --button-hover-background-color: #454d56;
  --drop-zone-background-color: #282c34;
  --border-color: #e0e0e0;
  --link-color: #0056b3;
}

/* Light Mode Styles */
:root[data-theme='light'] {
  --background-color: #f5f5f5;
  --text-color: #000000;
  --header-background-color: #f5f5f5;
  --button-background-color: #f41743;
  --button-hover-background-color: #0056b3;
  --drop-zone-background-color: #ffffff;
  --border-color: #e0e0e0;
  --link-color: #007bff;
}

/* Apply the variables to the elements */
html, body {
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100%; /* Ensure full height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  min-height: 100vh; /* Ensure .App takes at least full viewport height */
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: var(--header-background-color);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px; /* Space for theme toggle */
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

button {
  background-color: var(--button-background-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: var(--button-hover-background-color);
}

a {
  color: var(--link-color);
}

/* Theme toggle styles */
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjust size */
  height: 24px; /* Adjust size */
  margin: 0 10px; /* Space between icons and toggle */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px; /* Adjust radius for rounded corners */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Adjust size */
  width: 16px; /* Adjust size */
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(16px); /* Adjust size */
}

/* Icons styles */
.icon {
  font-size: 18px; /* Adjust size */
  margin: 0 5px;
}

/* Theme message styles */
.theme-message {
  position: absolute;
  top: 70px; /* Adjust according to your layout */
  left: 50%;
  transform: translateX(-50%);
  color: var(--text-color);
  font-size: 14px;
  z-index: 1002; /* Ensure it appears above other content */
  opacity: 0;
  transition: opacity 0.5s;
}

.theme-message.show {
  opacity: 1;
}
