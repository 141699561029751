/* Ensure the login container prevents scrolling */
html, body {
  overflow: hidden; /* Prevent scrolling on the entire document */
  height: 100%;
  margin: 0; /* Remove default margins */
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  padding: 20px;
  box-sizing: border-box;
}

.login-box {
  margin-bottom: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px; /* Maximum width for the login box */
  padding: 100px;
  border: 3px solid #1b1f25; /* Thick border around the box */
  border-radius: 10px; /* Rounded corners for the border */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Optional: Shadow effect */
  z-index: 1;
  background-color: #1b1f25;
}

.login-box h1 {
  margin-bottom: 50px; /* Spacing between heading and the input fields */
  font-size: 30px; /* Adjust font size if needed */
}

.login-box div {
  margin-bottom: 15px; /* Spacing between input fields */
  width: 100%;
}

.login-box label {
  display: block;
  margin-bottom: 5px;
}

.login-box input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc; /* Border for the input fields */
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
}

.login-box button {
  padding: 15px 20px;
  font-size: 16px;
  background-color: #1b1f25;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-box button:hover {
  background-color: #61dafb;
}

.google-login-button {
  display: flex;
  align-items: center; /* Vertically center text and logo */
  justify-content: center;
  background-color: white; /* White background to match Google's button */
  color: #4285f4; /* Google's blue for text */
  padding: 10px;
  width: 100%;
  border: 2px solid #db4437; /* Red border to match Google */
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1; /* Ensure consistent text height */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover */
}

.google-logo {
  width: 24px; /* Adjust width as needed */
  height: auto;
  margin-left: 10px;
  vertical-align: middle; /* Ensure SVG is aligned with the text */
}
.complybot {
  font-size: 36px;
  margin-bottom: 20px;
}

.comply {
  color: cyan;
}

.bot {
  color: white;
  padding: 0 5px;
  border-radius: 4px;
}