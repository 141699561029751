.side-nav {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #444a57a5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;
  transition: width 0.3s ease;
  z-index: 1000;
}

.side-nav.collapsed {
  width: 60px; /* Reduce width for collapsed mode */
}

.logo-container {
  margin-bottom: 0;
}

.logo {
  width: 100px;
  height: auto;
  transition: width 0.3s ease; /* Smooth transition when changing logo size */
}

/* Adjust collapsed logo size */
.collapsed-logo {
  width: 40px; /* Make collapsed logo smaller */
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This ensures icons are centered */
  gap: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: auto;
  cursor: pointer;
  padding: 5px 10px; /* Set default padding */
  transition: background-color 0.3s ease;
}

.nav-icon {
  font-size: 20px;
  margin-right: 10px;
}

/* Ensure icons are perfectly centered in collapsed mode */
.side-nav.collapsed .nav-item {
  justify-content: center;
  width: 100%; /* Ensures the icon spans the full width */
}

.side-nav.collapsed .nav-icon {
  margin-right: 0; /* Remove margin when collapsed to keep icon centered */
}

/* Text container to prevent line breaks */
.nav-text {
  font-size: 16px;
  white-space: nowrap;
  opacity: 0; /* Initially hide text */
  transition: opacity 0.3s ease font-size 0.3s ease, color 0.3s ease;
}

.side-nav:not(.collapsed) .nav-text {
  opacity: 1; /* Show text only when sidebar is expanded */
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.nav-item.active {
  color: cyan; /* Change text color to cyan */
  font-size: 22px; /* Increase text size */
  font-weight: bold; /* Optionally make it bold */
  transition: color 0.3s ease, font-size 0.3s ease; /* Smooth transition */
}

.divider-line {
  height: 1px;
  width: 80%;
  background-color: #444;
  margin: 10px 0;
}

.logout-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: auto;
  cursor: pointer;
  margin-top: auto;
  transition: background-color 0.3s ease, padding 0.3s ease;
  padding: 5px 10px;
}

.logout-item:hover {
  background-color: rgba(233, 53, 53, 0.847);
  border-radius: 4px;
}

.logout-icon {
  font-size: 15px;
  margin-right: 15px;
}

.side-nav.collapsed .logout-item {
  justify-content: center;
}

.side-nav.collapsed .logout-icon {
  margin-right: 0;
}

.collapse-button {
  position: absolute;
  top: 50%;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);
}

.collapse-icon {
  color: white;
  font-size: 16px;
}
